
import { mapActions, mapGetters } from "vuex";
import { objectToArrayOfObjects } from "~/common/helpers";
import { mdiMessageAlert, mdiCheckboxBlankCircle } from "@mdi/js";

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ["close", "confirm"],

  data() {
    return {
      mdiMessageAlert,
      mdiCheckboxBlankCircle,

      isFormValid: false,

      form: {
        name: "",
        email: "",
        text: "",
        type_id: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      filtersList: "profiles/getFiltersList",
    }),

    reportReasons() {
      return (
        objectToArrayOfObjects(this.filtersList?.profile_report_type) || []
      );
    },
  },

  methods: {
    ...mapActions({
      reportProfile: "profiles/reportProfile",
    }),

    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    close() {
      this.reset();
      this.$root.$emit("closeReportProfileModal", this.profile);
    },

    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        await this.reportProfile({
          profile_id: this.profile.id,
          ...this.form,
        });
        this.$notify({
          type: "success",
          message: this.$t("your-report-has-been-sent"),
        });
      } catch (error) {
        this.$notify({
          type: "error",
          message: this.$t("something-went-wrong"),
        });
        console.log(error);
      } finally {
        this.close();
      }
    },
  },
};
